import React, {ReactElement, FC} from "react";
import logo from '../logo.svg';

const Header:FC<any> = ():ReactElement => {
 return(
   <>
    <div className="flex justify-center w-full h-20">
         <div className="flex w-11/12 justify-between border-b-2 border-solid border-gray-100 pb-2">
            <div className="my-2 items-start">
               <img style={{height: "30px", marginTop:"16px"}} alt="TaxLeopard Logo" src={logo}/>
            </div>
         
            <div className="my-auto float-right mr-5">
               <p className="text-xs text-right" style={{color: "gray", fontSize: "8px"}}>Powered By</p>
               <img style={{height: "29px", width: "104px"}} alt="CarClarity Logo" src="https://widgets.carclarity.com.au/img/CarClarity.2d0241f0.svg"/>
            </div>
         </div>
   </div>
    </>
 )
}

export default Header